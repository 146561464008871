module.exports = {
    LINGUA_BASE_PATH: 'p2-assistant',
    // the values in the CARDS map must match the keys in the CARD_CATEGORY_MAPPING map
    CARDS: {
        MISSED_OPPORTUNITY_CARD: 'POLARIS_MISSED_OPPORTUNITY_CARD',
        WON_BOOKING_CARD: 'POLARIS_WON_BOOKING_CARD',
        PROPERTY_HEADLINE_CARD: 'PROPERTY_HEADLINE_CARD',
        PROPERTY_DESCRIPTION_CARD: 'PROPERTY_DESCRIPTION_CARD',
        PROPERTY_PHOTO_CARD: 'POLARIS_PHOTOS_RECOMMENDATION_CARD',
        PROPERTY_REVIEWS_CARD: 'PROPERTY_REVIEWS_CARD'
    },
    CARD_CATEGORIES: [
        'RATE_RECOMMENDATIONS',
        'PROPERTY_RECOMMENDATIONS',
        'COMPETITIVE_RECOMMENDATIONS',
        'MARKET_RECOMMENDATIONS',
        'MISC_RECOMMENDATIONS'
    ],
    CARD_CATEGORY_MISC: 'MISC_RECOMMENDATIONS',
    CARD_CATEGORY_MARKET: 'MARKET_RECOMMENDATIONS',
    // the keys in the CARD_CATEGORY_MAPPING map must match the values in the CARDS map
    CARD_CATEGORY_MAPPING: {
        POLARIS_MISSED_OPPORTUNITY_CARD: 'COMPETITIVE_RECOMMENDATIONS',
        POLARIS_MM_OPPORTUNITY_CARD: 'RATE_RECOMMENDATIONS',
        POLARIS_WON_BOOKING_CARD: 'COMPETITIVE_RECOMMENDATIONS',
        PROPERTY_HEADLINE_CARD: 'PROPERTY_RECOMMENDATIONS',
        PROPERTY_DESCRIPTION_CARD: 'PROPERTY_RECOMMENDATIONS',
        POLARIS_PHOTOS_RECOMMENDATION_CARD: 'PROPERTY_RECOMMENDATIONS',
        PROPERTY_REVIEWS_CARD: 'PROPERTY_RECOMMENDATIONS'
    },
    OPPORTUNITY_ENUM_STRING: `PROPERTY_RECOMMENDATIONS
        RATE_RECOMMENDATIONS
        MARKET_RECOMMENDATIONS
        COMPETITIVE_RECOMMENDATIONS`,
    FILTERS_ARRAY: {
        LOCATION: {NAME: '.Sidebar.Body.propertyfilters.propertysegmentation.location', VALUE: 'location'},
        OFFICE: {NAME: '.Sidebar.Body.propertyfilters.propertysegmentation.office', VALUE: 'office'},
        UNITGROUP: {NAME: '.Sidebar.Body.propertyfilters.propertysegmentation.unitgroup', VALUE: 'unitgroup'},
        UNITTYPE: {NAME: '.Sidebar.Body.propertyfilters.propertysegmentation.unittype', VALUE: 'unittype'},
        UNITSIZE: {NAME: '.Sidebar.Body.propertyfilters.propertysegmentation.unitsize', VALUE: 'unitsize'},
        UNIT: {NAME: '.Sidebar.Body.propertyfilters.propertysegmentation.unit', VALUE: 'unit'}
    },
    FILTERS_PREFIXES: {
        DASH: 'dashfilters',
        OPP: 'oppfilters'
    },
    HASESSION: 'HASESSION',
    HATGC: 'HATGC',
    AGENCY_DOMAIN_COOKIE: 'agencyDomain',
    COOKIES_REQUIRING_DOMAIN_REWRITE: ['HASESSION', 'HATGC'],
    SET_COOKIE: 'set-cookie',
    SESSION_CHECK_INTERVAL: 10,
    PIXEL_LENGTH_FOR_ISMOBILE_CHECK: 823,
    NOT_NOW_SESSION_STORAGE: 'email-update-not-now',
    // make sure these values match the values in mixins.less
    CHART_COLORS: ['#6585ff', '#ec3e8e', '#00a700', '#3a4046', '#0249a5'],
    DATA_TYPES: {
        NUMBER: 'number',
        CURRENCY: 'currency',
        PERCENTAGE: 'percentage'
    },
    CDN_RELEASE: '4.3.2',
    CDN_SITE: 'escapia',
    OPP_CARD_MISSING_LIST_TITLE: 'Missing Title',
    OPP_CARD_MISSING_LIST_BODY: 'Missing Body',
    OPP_CARD_MISSING_LIST_HEADER: 'Missing Header',
    OPP_CARD_MAX_CATEGORY_COUNT: 25,
    EDAP: {
        UNKNOWN_USER: 'ANONYMOUS'
    },
    SEGMENT_TYPES: {
        MONTH: 'MONTH'
    },
    INSIGHT_TYPES: {
        BUSINESS_SEGMENT_INSIGHT: 'businessSegmentInsight',
        PROPERTY_INSIGHT: 'propertyInsight',
        MESSAGE: 'message'
    },
    METRICS: {
        REVENUE: 'Revenue',
        BOOKINGS: 'Bookings',
        NIGHTS_SOLD: 'NightsSold',
        OCCUPANCY: 'Occupancy',
        ADR: 'ADR',
        REVPAR: 'RevPAR'
    },
    METRIC_KEYS: {
        RENT: 'rent',
        BOOKINGS: 'bookings',
        NIGHTS: 'nights',
        OCCUPANCY: 'occupancy',
        ADR: 'adr',
        REVPAR: 'revpar'
    },
    LUXON_DATE_FORMAT_YYYY_MM_DD: 'yyyy-MM-dd',
    DATE_FORMAT_YYYY_MM_DD: 'YYYY-MM-DD',
    DATE_FORMAT_NOTIFICATIONS_HEADER: 'll',
    DATE_FORMAT_NOTIFICATIONS_LAST_MESSAGE: 'LT',
    DATE_FORMAT_EXPORT_REPORT: 'LLL',
    DATE_FORMAT_SHORT_EXPORT_REPORT: 'l',
    ESCAPIA_ROLES: ['Agency_Administrator', 'UberAdmin'],
    ESCAPIA_ROLES_ENUM: {
        Accountant: 'ACCOUNTANT',
        Accounting_Manager: 'ACCOUNTING_MANAGER',
        Agency_Administrator: 'AGENCY_ADMINISTRATOR',
        Communications_User: 'COMMUNICATIONS_USER',
        Credit_Card_Viewer: 'CREDIT_CARD_VIEWER',
        Customer: 'CUSTOMER',
        EscapiaNET_Administrator: 'ESCAPIANET_ADMINISTRATOR',
        EVRNAgent: 'EVRNAGENT',
        Housekeeper: 'HOUSEKEEPER',
        Housekeeping_Manager: 'HOUSEKEEPING_MANAGER',
        Housekeeping_Supervisor: 'HOUSEKEEPING_SUPERVISOR',
        Marketer: 'MARKETER',
        Marketing_Manager: 'MARKETING_MANAGER',
        Member: 'MEMBER',
        Owner: 'OWNER',
        PM_Manager: 'PM_MANAGER',
        Property_Manager: 'PROPERTY_MANAGER',
        Prospect: 'PROSPECT',
        Rate_Manager: 'RATE_MANAGER',
        Report_Manager: 'REPORT_MANAGER',
        Reservationist: 'RESERVATIONIST',
        Reservations_Manager: 'RESERVATIONS_MANAGER',
        Role_Administrator: 'ROLE_ADMINISTRATOR',
        Technician: 'TECHNICIAN',
        Technician_Manager: 'TECHNICIAN_MANAGER',
        UberAdmin: 'UBERADMIN',
        ALL: 'ALL'
    },
    ESCAPIA_HOUSEKEEPING_ROLES: ['Housekeeper', 'Housekeeping_Manager', 'Housekeeping_Supervisor'],
    ADMIN_PANEL_ROLE: 'UberAdmin',
    PERFORMANCE_DASHBOARD_INFO:
        'http://support.homeawaysoftware.com/articles/en_US/Article/HASW-Using-the-Performance-Dashboard?subdir=Escapia',
    HELP_INFO: 'https://support.escapia.com/escapia',
    OPPORTUNITIES_HUB_INFO:
        'http://support.homeawaysoftware.com/articles/en_US/Article/HASW-Understanding-the-Opportunities-Hub?subdir=Escapia',
    ROLE_CHECK: {
        OK: 'OK',
        BLOCKED: 'BLOCKED',
        INVALID: 'INVALID',
        REGEX: /OK|BLOCKED/
    },
    PATHS: {
        /*
            The p2-assistant was developed with an assumption that pretty much all app urls
            would be at /p/*.  However, for developing the Bernie application we plan to
            launch with a prefixed url, so pages will be at something like /alpha/p/*.

            Ultimately though the Bernie app will need to work with the same urls as
            p2-assistant.  So, we need the app to work with an optional prefix.  However,
            there are many uses of the PATHS.* constants which are difficult to find and
            update, and a regular update would really only allow the code to work with one
            prefix or another...

            Thus we have added this function which detects the optional path prefix.  This
            function is called from within new getter functions in the `PATHS` object, which
            allows our code to be reusable with all path prefixes without changing how the
            app uses the PATH values.

            The optionalPath was added for server code, which doesn't have access to globalThis,
            to calculate prefix for a given path like /alpha/p/dashboard
        */
        // eslint-disable-next-line no-undef
        appPathPrefix(path = globalThis?.location?.pathname || null) {
            const nonPrefixedRoots = ['p', 'internal', 'login', 'logout', 'selectAgency'];
            let pathParts = path?.split('/');
            if (!pathParts || pathParts.length === 0) return '';

            const root = pathParts.find((s) => nonPrefixedRoots.includes(s));
            if (!root) return '';

            let prefix = pathParts?.slice(0, pathParts.indexOf(root))?.join('/');
            if (!prefix || prefix === '/') {
                prefix = '';
            }
            return prefix;
        },
        get LOGOUT() {
            return `${this.appPathPrefix()}/logout`;
        },
        get LOGIN() {
            return `${this.appPathPrefix()}/p/login`;
        },
        get LOGIN_ROUTE() {
            return `${this.appPathPrefix()}/login`;
        },
        get INTERNAL_LOGIN() {
            return `${this.appPathPrefix()}/internal/login`;
        },
        get INTERNAL_SELECT_AGENCY() {
            return `${this.appPathPrefix()}/internal/selectAgency`;
        },
        get SELECT_AGENCY() {
            return `${this.appPathPrefix()}/selectAgency`;
        },
        get COMM_HUB_SCOPE() {
            return '/p/a/communication-hub/inbox';
        },
        get COMM_HUB() {
            return `${this.appPathPrefix()}/p/a/communication-hub/inbox`;
        },
        get DASH() {
            return `${this.appPathPrefix()}/p/dashboard`;
        },
        get OPP() {
            return `${this.appPathPrefix()}/p/opportunities`;
        },
        get ESCAPIA_HOME() {
            return `${this.appPathPrefix()}/p/escapia/customerHome`;
        },
        get ESCAPIA_HOUSEKEEPER_HOME() {
            return `${this.appPathPrefix()}/p/a/housekeeping/housekeeping-dashboard`;
        },
        get SAD_PUPPY() {
            return `${this.appPathPrefix()}/p/permission-error`;
        },
        get LOG() {
            return `${this.appPathPrefix()}/log`;
        },
        get P_ADMIN() {
            return `${this.appPathPrefix()}/p/partneradmin`;
        },
        get ERROR() {
            return `${this.appPathPrefix()}/p/error`;
        },
        get GRAPHQL() {
            return `${this.appPathPrefix()}/graphql`;
        },
        get GRAPHQL_GATEWAY() {
            return `${this.appPathPrefix()}/gateway/graphql`;
        },
        get APP_PATH() {
            return `${this.appPathPrefix()}/p`;
        },
        get VALIDATE_USER() {
            return `${this.appPathPrefix()}/validateUser`;
        },
        get VALIDATE_MFACODE() {
            return `${this.appPathPrefix()}/validateMfaCode`;
        },
        get SESSION_CHECK() {
            return `${this.appPathPrefix()}/sessionCheck`;
        },
        get MENU() {
            return `${this.appPathPrefix()}/menu`;
        },
        get SISENSE_SSO() {
            return `${this.appPathPrefix()}/sisense/ssoin`;
        },
        get SERVICE_WORKER() {
            return `${this.appPathPrefix()}/p/serviceWorker`;
        },
        get UNITS_DRAFTS() {
            return `${this.appPathPrefix()}/p/units/drafts`;
        },
        get DOCUMENT_API_ROOT() {
            return `${this.appPathPrefix()}/api/document`;
        },
        get API_FORWARDING_ROOT() {
            return `${this.appPathPrefix()}/api/property-managers`;
        },
        get BUILDINFO() {
            return `${this.appPathPrefix()}/buildInfo`;
        }
    },
    DROPDOWN_TYPE: {
        PREVIOUS_YEAR: 'previousYear',
        CURRENT_YEAR: 'currentYear',
        NEXT_YEAR: 'nextYear',
        NEXT_12_MONTHS: 'next12Months'
    },
    DROPDOWN_LABEL_KEYS: {
        NEXT_12_MONTHS: 'next12m',
        LAST_12_MONTHS: 'last12m',
        LAST_12_MONTHS_TOTAL: 'last12mtotal'
    },
    TERMS_AND_CONDITIONS: 'https://www.homeaway.com/info/about-us/legal/terms-conditions',
    PRIVACY_POLICY: 'https://www.homeaway.com/info/legal/privacy-policy/',
    SIDEBAR: {
        FILTERS: 'FILTERS',
        GLOBAL_SEARCH: 'GLOBAL_SEARCH'
    },
    GLOBAL_SEARCH_QUERY_STRING: `query doSearch($agencyId: Int!, $filters: JSON!, $searchType: GlobalSearchEnum!) {
      doSearch(agencyId: $agencyId, filters: $filters, searchType: $searchType) {
        searchType
        results {
          RES {
            LinkText
            FirstName
            LastName
            ConfirmationCode
            CreationDate
            Email
            ObjectType
            Url
            Path
          }
          BKG {
            LinkText
            UnitCode
            StartDate
            EndDate
            ObjectType
            Url
            Path
          }
          UNIT {
            LinkText
            UnitCode
            City
            Country
            ObjectType
            Url
            Path
          }
          CONTACT {
            LinkText
            Email
            Id
            FullName
            Phone
            Roles
            ObjectType
            Url
            Path
          }
          JD {
            LinkText
            Description
            Type
            ObjectType
            Url
            Path
          }
          SVC {
            LinkText
            Description
            ObjectType
            Url
            Path
          }
          REPORT {
            LinkText
            Category
            ObjectType
            Url
            Path
          }
        }
        status
      }
    }
    `,
    GLOBAL_SEARCH_KEYS: {
        RES: {
            name: 'Reservation',
            key: 'RES'
        },
        BKG: {
            name: 'Bookings',
            key: 'BKG'
        },
        UNIT: {
            name: 'Units',
            key: 'UNIT'
        },
        CONTACT: {
            name: 'Contact',
            key: 'CONTACT'
        },
        JD: {
            name: 'JournalDocument',
            key: 'JD'
        },
        SVC: {
            name: 'Service',
            key: 'SVC'
        },
        REPORT: {
            name: 'Reports',
            key: 'REPORT'
        }
    },
    // NOT ALLOWED CHARACTERS: unicode currency, gen punc, supp punc, special codes, spacing modifying letters, subscripts + superscripts,
    //      combining diacritical marks for symbols, letterlike symbols, number forms, arrows, math operators, misc technical, control
    //      pictures, optical char recognition, box drawing, block elements, geometric shapes, misc symbols, dingbats, misc math symbols,
    //      supplemental arrows, braille, supplemental math operators, misc symbols and arrows, ideographic desc chars, modifier tone letters,
    //      surrogates, private use, variation selectors, vertical forms, specials, enclosed alphanumerics
    //      ,\/#!$%\^&\*;:{}=_`~()@\+\?><\[\]"
    // ALLOWED CHARACTERS: spaces, alphabet/digits, combining diacritical marks, phonetic extensions + supplments in all character based languages, greek, cyrillic, latin, etc.
    // https://unicode-table.com/en/
    // http://kourge.net/projects/regexp-unicode-block
    // these are best used as string.search(SANITIZATION_REGEXES) === -1
    SANITIZATION_REGEXES: {
        // eslint-disable-next-line no-control-regex, no-useless-escape, no-misleading-character-class
        FULL: /[\u20A0-\u20CF\u2000-\u206F\u2E00-\u2E7F\u02B0-\u02FF\u2070-\u209F\u20D0-\u20FF\u2100-\u214F\u2150-\u218F\u2190-\u21FF\u2200-\u22FF\u2300-\u23FF\u2400-\u243F\u2440-\u245F\u2500-\u257F\u2580-\u259F\u25A0-\u25FF\u2600-\u26FF\u2700-\u27BF\u27C0-\u27EF\u27F0-\u27FF\u2800-\u28FF\u2900-\u297F\u2980-\u29FF\u2A00-\u2AFF\u2B00-\u2BFF\u2FF0-\u2FFF\uA700-\uA71F\uD800-\uDB7F\uDB80-\uDBFF\uDC00-\uDFFF\uE000-\uF8FF\uFE00-\uFE0F\uFE10-\uFE1F\uFFF0-\uFFFF\u2460-\u24FF\u0009\u000A\u000D\u00A0,\/#!$%\^&\*;:{}=_`~()@\+\?><\[\]|"]/,
        ALLOW_HASHTAG:
            // eslint-disable-next-line no-control-regex, no-useless-escape, no-misleading-character-class
            /[\u20A0-\u20CF\u2000-\u206F\u2E00-\u2E7F\u02B0-\u02FF\u2070-\u209F\u20D0-\u20FF\u2100-\u214F\u2150-\u218F\u2190-\u21FF\u2200-\u22FF\u2300-\u23FF\u2400-\u243F\u2440-\u245F\u2500-\u257F\u2580-\u259F\u25A0-\u25FF\u2600-\u26FF\u2700-\u27BF\u27C0-\u27EF\u27F0-\u27FF\u2800-\u28FF\u2900-\u297F\u2980-\u29FF\u2A00-\u2AFF\u2B00-\u2BFF\u2FF0-\u2FFF\uA700-\uA71F\uD800-\uDB7F\uDB80-\uDBFF\uDC00-\uDFFF\uE000-\uF8FF\uFE00-\uFE0F\uFE10-\uFE1F\uFFF0-\uFFFF\u2460-\u24FF\u0009\u000A\u000D\u00A0,\/!$%\^&\*;:{}=_`~()@\+\?><\[\]|"]/,
        ALLOW_KEYBOARD_SPECIAL_CHARS:
            // eslint-disable-next-line no-control-regex, no-useless-escape, no-misleading-character-class
            /[\u20A0-\u20CF\u2000-\u206F\u2E00-\u2E7F\u02B0-\u02FF\u2070-\u209F\u20D0-\u20FF\u2100-\u214F\u2150-\u218F\u2190-\u21FF\u2200-\u22FF\u2300-\u23FF\u2400-\u243F\u2440-\u245F\u2500-\u257F\u2580-\u259F\u25A0-\u25FF\u2600-\u26FF\u2700-\u27BF\u27C0-\u27EF\u27F0-\u27FF\u2800-\u28FF\u2900-\u297F\u2980-\u29FF\u2A00-\u2AFF\u2B00-\u2BFF\u2FF0-\u2FFF\uA700-\uA71F\uD800-\uDB7F\uDB80-\uDBFF\uDC00-\uDFFF\uE000-\uF8FF\uFE00-\uFE0F\uFE10-\uFE1F\uFFF0-\uFFFF\u2460-\u24FF\u0009\u000A\u000D\u00A0]/,
        ALLOW_FORWARD_SLASH:
            // eslint-disable-next-line no-control-regex, no-useless-escape, no-misleading-character-class
            /[\u20A0-\u20CF\u2000-\u206F\u2E00-\u2E7F\u02B0-\u02FF\u2070-\u209F\u20D0-\u20FF\u2100-\u214F\u2150-\u218F\u2190-\u21FF\u2200-\u22FF\u2300-\u23FF\u2400-\u243F\u2440-\u245F\u2500-\u257F\u2580-\u259F\u25A0-\u25FF\u2600-\u26FF\u2700-\u27BF\u27C0-\u27EF\u27F0-\u27FF\u2800-\u28FF\u2900-\u297F\u2980-\u29FF\u2A00-\u2AFF\u2B00-\u2BFF\u2FF0-\u2FFF\uA700-\uA71F\uD800-\uDB7F\uDB80-\uDBFF\uDC00-\uDFFF\uE000-\uF8FF\uFE00-\uFE0F\uFE10-\uFE1F\uFFF0-\uFFFF\u2460-\u24FF\u0009\u000A\u000D\u00A0,#!$%\^&\*;:{}=_`~()@\+\?><\[\]|"]/
    },
    PENDO_TOUR_KEYS: {
        SITE: 'site-tour',
        PERFORMANCE_DASHBOARD: 'dashboard-tour',
        OPPORTUNITIES_HUB: 'opportunities-tour'
    },
    FINGERPRINT_DATA_SEPARATOR: '###',
    MFA_TEMPLATE_NAMES: {
        FIRST: 'Escapia',
        LAST: 'User'
    },
    EVRN_OPTIONS_STATUS_TYPE: {
        NOT_STARTED: 0,
        ACTIVATE_PARTNERS_COMPLETE: 1,
        DISTRIBUTE_UNITS_COMPLETE: 2,
        ASSIGN_UNIT_TYPES_COMPLETE: 3,
        SETUP_POLICIES_COMPLETE: 4,
        AMENITY_MAPPING_COMPLETE: 5,
        ADDRESS_VERIFICATION_COMPLETE: 6,
        AUTO_OPTIONS_COMPLETE: 7
    },
    POSTMESSAGE_TYPE: {
        SHOWAUDITDIALOG: 'showAuditDialog',
        HIDEAUDITDIALOG: 'hideAuditDialog',
        AUDITDIALOGERROR: 'auditDialogError',
        AUDITCOMMENTENTERED: 'auditCommentEntered',
        MARCO: 'marco',
        POLO: 'polo',
        FISH_OUT_OF_WATER: 'fishOutOfWater',
        RETURN_TO_PREVIOUS_LOCATION: 'ReturnToPreviousLocation',
        UPDATE_BREADCRUMB: 'updateBreadcrumb',
        MESSAGE: 'message',
        ON_MENU_ITEM_CLICKED: 'onMenuItemClicked',
        ON_DROPDOWN_OPEN: 'onDropdownOpen',
        ON_DROPDOWN_CLOSED: 'onDropdownClosed',
        CLASSIC_KEY_EVENT: 'ClassicKeyboardEvent',
        REDUX_DISPATCH: 'REDUX_DISPATCH'
    },
    MAX_RECENT_SEARCHES: 10,
    PROVIDERS: {
        RECENTS: {
            STORAGE_NAME: 'recentSearches',
            CONTEXT_NAME: 'recentSearchContext',
            MAX_RECENT_SEARCHES: 10
        },
        FAVORITES: {
            STORAGE_NAME: 'favoritesSearches',
            CONTEXT_NAME: 'favoritesContext',
            MAX_FAVORITES: 20
        },
        INBOX_RELOAD: {
            STORAGE_NAME: 'inboxReload',
            CONTEXT_NAME: 'serviceWorkersContext'
        }
    },
    FINGERPRINT_EXCLUDES: {
        excludes: {
            adBlock: true,
            audio: true,
            localStorage: true,
            sessionStorage: true,
            enumerateDevices: true
        }
    },
    CUSTOM_ASSET_IDS: {
        // 4th path param in dynamic asset route
        UNITS: 'units',
        UNIT_LIST: 'escapia-unit-list',
        RATES_MANAGER: 'rates-manager',
        GLOBAL_RATES_SETTINGS: 'global-rates-settings',
        RATES_STRATEGY: 'rates-strategy',
        ADDITIONAL_FIELDS_MANAGEMENT: 'additional-field-management',
        INBOX: 'inbox',
        REACT_P2_NETWORK_PARTNERS: 'react-p2-network-partners',
        ESCAPIA_CUSTOMER_BILLING: 'escapia-customer-billing',
        REACT_P2_ESCAPIA_CONNECT: 'escapia-connect',
        SIGNATURE_PLATFORM: 'signature-platform',
        REACT_ESCAPIA_FRONTDESK_DASHBOARD: 'business-console',
        INVOICES: 'invoices',
        PRICE_OVERRIDE_REPORT: 'price-override-report',
        CHANNEL_COLLECTED_DETAILS: 'channel-collected-details',
        VRBO_CHANNEL_COLLECTED_DETAILS: 'vrbo-channel-collected-details',
        CHANNEL_REMITTED_TAXES_REPORT: 'channel-remitted-taxes-report',
        EC_TAX_REMITTANCE_CONFIGURATION: 'ec-tax-remittance-configuration',
        NEW_AUDIT_LOG: 'audit-log',
        NOTIFICATIONS: 'notifications',
        HOUSEKEEPING_DASHBOARD: 'housekeeping-dashboard',
        GUEST_GUIDES: 'guest-guides',
        NOTIFICATIONS_DASHBOARD: 'notifications-dashboard',
        RESERVATION_CANCEL: 'reservation-cancel',
        VRBO_RESERVATION_UPDATE: 'vrbo-reservation-update'
    },
    INTERNAL_LOGIN_RECENT_AGENCY_LIST: 'INTERNAL_LOGIN_RECENT_AGENCY_LIST',
    DIALOG_SIZES: {
        DEFAULT: 'DEFAULT',
        FULLSCREEN: 'FULLSCREEN'
    },
    DRAWER_SIDES: {
        LEFT: 'LEFT',
        RIGHT: 'RIGHT'
    },
    INBOX_ENDPATH: '/inbox',
    NOTES_RESULT: `
        id
        authorFullName
        authorPublicUuid
        noteContent
        noteContextIds {
            key
            value
        }
        noteType
        createdDateTimeUTC
        updatedDateTimeUTC
    `
};
